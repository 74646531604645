import * as React from "react"

import Seo from "../common/components/seo"
import Header from "../common/components/organism/Header"
import Footer from "../common/components/organism/Footer"

const NotFoundPage = () => (
  <>
    <Header />
    <Seo title="404: Not found" />
    <h1>404: Not Found</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    <Footer />
  </>
)

export default NotFoundPage
